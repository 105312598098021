import React from "react";
import { useMediaQuery } from "react-responsive";

function Home() {
  const isMobile = useMediaQuery({ query: "(max-width: 660px)" });

  return (
    <main>
      <p>
        <span className="line">
          Code wizards, engineers, scientists and product designers.
        </span>
        <span className="line">
          <span style={{ fontWeight: 900, textDecoration: "underline" }}>
            We
          </span>{" "}
          are passionate about building things. Building stories.
        </span>
      </p>

      <h3>Some of our ongoing efforts:</h3>
      <ul>
        <li>
          <a href="https://www.straqen.ai/" target="_blank">
            <span className="project">straqen.ai</span>;{" "}
            <span hidden={isMobile}>voice preserving speech-to-speech</span>
          </a>
        </li>
        {/* <li>
          <a href="https://www.chatlib.co/" target="_blank">
            <span className="project">chatlib.co</span>;{" "}
            <span hidden={isMobile}>adapter to chat with your libraries</span>
          </a>
        </li> */}
        <li>
          <a href="https://www.filtro.ai/" target="_blank">
            <span className="project">filtro.ai</span>;{" "}
            <span hidden={isMobile}>leverage chatGPT without data leaks</span>
          </a>
        </li>
        <li>
          <a target="_blank">
            yoksaa.app;{" "}
            <span hidden={isMobile}>your crispy travel companion</span>
          </a>
        </li>
        {/* <li>
          <a target="_blank">
            renable.app;{" "}
            <span hidden={isMobile}>beat distractions, boost charities</span>
          </a>
        </li> */}
      </ul>

      <p>Founded in Zürich (CH) 2023. Now in NYC, Boston, London.</p>
    </main>
  );
}

export default Home;
