import React from "react";

import Header from "../components/Header";
import Home from "../components/Home";
import Footer from "../components/Footer";

function Root() {
  return (
    <main>
      <Header />
      <Home />
      <Footer />
    </main>
  );
}

export default Root;
