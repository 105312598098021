import { useRouteError } from "react-router-dom";
import Header from "../components/Header";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <main>
      <Header />
      <p>Page not found. You're not supposed to be here.</p>
    </main>
  );
}
