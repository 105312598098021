import React from "react";

function Footer() {
  const empty = "";
  const body =
    "You can email us at: andreakiro [dot] pinto [at] gmail [dot] com.";
  const mailto = `mailto:${empty}?subject=${empty}&body=${body}`;

  return (
    <footer>
      <p style={{ fontWeight: 900 }}>
        Do you want to get involved? <a href={mailto}>Email us</a>. <br />
      </p>
    </footer>
  );
}

export default Footer;
