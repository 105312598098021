import React from "react";
import { Link, useLocation } from "react-router-dom";

function Header() {
  const location = useLocation();
  const ymanifold = (
    <>
      <span className="y">(y)</span>manifold.
    </>
  );

  return (
    <header>
      <h1>
        {location.pathname !== "/" ? (
          <Link to="/">{ymanifold}</Link>
        ) : (
          ymanifold
        )}
      </h1>
    </header>
  );
}

export default Header;
